<template>
    <v-menu
        offset-y
        z-index="200"
        max-width="480"
    >
        <template v-slot:activator="{ on, attrs }">
            <span class="btn icon-btn" v-bind="attrs" v-on="on">
                <i class="icon icon-notifications"></i>
                <span v-if="unreaded.length > 0" class="status-led status-led--red"></span>
            </span>
        </template>
        <v-card class="notifications">
            <v-card-text>
                <v-list class="notifications-list">
                    <v-list-item
                        v-for="(item, index) in notifications"
                        class="notification-item pl-0 pr-0"
                        :class="{'notification-item--new': !item.read }"
                        :key="index"
                        @click="notificationClick(item)"
                    >
                        <v-list-item-avatar size="8"></v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-html="item.title"></v-list-item-title>
                            <v-list-item-subtitle v-html="item.message"></v-list-item-subtitle>
                            <div>
                                {{ item.date }}
                            </div>

                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <Btn
                    class="w-100"
                    :to="{name: 'notifications'}"
                    :loading="loading"
                    :disabled="loading"
                >
                    Все уведомления
                </Btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import Btn from "@/components/Form/Btn.vue";

export default {
    name: 'NotificationPopup',
    components: {Btn},

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        unreaded() {
            return this.notifications.filter(item => !item.read);
        }
    },

    data() {
        return {
            notifications: [],
            loading: false,
            nextLink: true
        };
    },

    methods: {
        async fetchNotifications(page = 1) {
            this.loading = true;
            const response = await this.$http.get(
                'admin/notification',
                {
                    params: {
                        perPage: 6,
                        page
                    }
                });
            this.notifications = response.body.data;
            this.nextLink = response.body.meta.current_page < response.body.meta.last_page;
            this.loading = false;
        },
        notificationClick(item) {
            this.$emit('notification-click', item);
        },
        nextItems() {
            this.fetchNotifications(this.notifications.length / 10 + 1);
        }
    },
    mounted() {
        this.fetchNotifications();
    }
};
</script>

<style lang="scss">
.notifications {
    .v-card__text {
        padding: $grid-gutter $grid-gutter $grid-gutter /2 $grid-gutter !important;
    }

    .v-card__actions {
        padding: $grid-gutter / 2 $grid-gutter $grid-gutter $grid-gutter;
    }
}

.notifications-list {
    &.v-list {
        padding: 0;
    }
}

.notification-item {
    margin-bottom: $grid-gutter;
    opacity: .6;

    &--new {
        opacity: 1;

        .v-list-item__avatar {
            background-color: var(--primary);
        }

        //.v-list-item__title {
        //    &:before {
        //        display: inline-block;
        //        margin-right: $grid-gutter / 4;
        //        margin-bottom: 2px;
        //        content: "";
        //        width: 6px;
        //        height: 6px;
        //        border-radius: 100%;
        //        background-color: var(--primary);
        //    }
        //}
    }

    &:last-child {
        margin-bottom: 0;
    }

    .v-list-item__avatar {
        margin-top: 0;
        margin-bottom: 0;
        border: 1px solid var(--primary);
    }

    .v-list-item__subtitle {
        color: var(--text-color-light) !important;
    }

    .v-list-item__content {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
    }

    .v-list-item__subtitle {
        padding-top: $grid-gutter / 4;
        padding-bottom: $grid-gutter / 4;

        overflow: auto;
        text-overflow: unset;
        white-space: normal;
    }
}
</style>
