<script>
import moment from "moment";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
    name: "User",

    components: {UserAvatar},

    props: {
        item: {
            type: Object,
            required: true
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        vacationFrom() {
            if(!this.item.vacation)
                return null;

            return moment(this.item.vacation.from_date).format('DD.MM.YYYY');
        },
        vacationTo() {
            if(!this.item.vacation)
                return null;

            return moment(this.item.vacation.to_date).format('DD.MM.YYYY');
        },
    },

    methods: {
        onClick() {
            if(this.disabled)
                return;

            this.$emit('click')
        }
    }
}
</script>

<template>
    <v-list subheader two-line class="background_none usr">
        <v-list-item class="min_height_auto" @click="onClick">
            <UserAvatar :img="item.photo" :alt="item.name" />

            <span v-if="item.vacation" class="usr__vacation">
                <v-icon>mdi-umbrella-beach</v-icon>
                <div class="usr__vacation-info">
                    <b>{{item.vacation.type}}</b><br>
                    {{ this.vacationFrom }} - {{ this.vacationTo }}
                    <b>{{ $t('columns.admin.vacation.replacing') }}</b>:<br>{{item.vacation.replacing.name}}
                </div>
            </span>

            <v-list-item-content class="py-0 title_subtitle">
                <v-list-item-title>
                    {{item.name}}
                </v-list-item-title>
                <v-list-item-subtitle>
                    <slot name="position">
                        {{formatPhoneNumber(item.phone)}}
                    </slot>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<style lang="scss">
.usr {
    .v-list-item {
        padding: 0;
    }

    .v-avatar {
        padding: 0;
        margin: 0;
    }

    &.v-list--two-line {
        padding: 0;

        .v-list-item {
            .v-list-item__avatar {
                &:not(.v-list-item__avatar--horizontal) {
                    margin: 0 10px 0 0;
                    padding: 0 !important;
                }

            }

            .v-list-item__content {
                padding: 0;
            }
        }
    }
}

.usr__vacation-info {
    width: 200px;
    background-color: #fff;
    position: absolute;
    z-index: 10;
    padding: $grid-gutter / 2;
    display: none;
    text-align: left;
    color: var(--text-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

$vacation-icon-size: 16px;
.usr__vacation {
    display: inline-block;
    position: absolute;
    left: calc(42px - #{$vacation-icon-size} + 2px);
    bottom: -2px;
    border-radius: 100%;
    background-color: orange;
    width: $vacation-icon-size;
    height: $vacation-icon-size;
    border: 1px solid #fff;

    &:hover {
        .usr__vacation-info {
            display: block;
        }
    }

    .v-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 100%;
        color: #fff;
    }
}

.usr__avatar {
    //overflow: visible;
}
</style>
